import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ImagilityBaseResponse } from '../data/models/response.model';
import { map } from 'rxjs/internal/operators/map';
import * as CryptoJS from 'crypto-js';

@Injectable({ providedIn: 'root' })
export class GCI140Service {
    encriptedData: any;
    constructor(private httpClient: HttpClient) { }

    getI140ReviewComments(superTaskId: number, caseId: number) {
        return this.httpClient.get(`/task/${superTaskId}/case/${caseId}/review`).pipe(
            map((response: ImagilityBaseResponse) => {
                if (response && (response.status === 200 || response.status === 204)) {
                    return response;
                }
            })
        );
    }

    postI140ReviewComments(superTaskId: number, caseId: number, payload: any) {
        return this.httpClient.post(`/task/${superTaskId}/case/${caseId}/review`, payload).pipe(
            map((response: ImagilityBaseResponse) => {
                if (response && response.status === 200) {
                    return response;
                }
            })
        );
    }

    getPetionReviewerDetails(superTaskId: number, caseId: number,) {
        return this.httpClient.get(`/task/${superTaskId}/case/${caseId}/reviewer`).pipe(
            map((response: ImagilityBaseResponse) => {
                if (response.status === 200) {
                    return response;
                }
            })
        );
    }


    savePetionReviewerDetails(superTaskId: number, caseId: number, reviewerId: number) {
        return this.httpClient.post(`/task/${superTaskId}/case/${caseId}/reviewer/${reviewerId}`, {}).pipe(
            map((response: ImagilityBaseResponse) => {
                if (response.status === 200) {
                    return response;
                }
            })
        );
    }

    getI140StatsReponses() {
        return this.httpClient.get(`/gc/perm/I140STAT/fetchLookupValues`).pipe(
            map((response: ImagilityBaseResponse) => {
                if (response && (response.status === 200 || response.status === 204)) {
                    return response;
                }
            })
        );
    }

    getI140LetterTemplateList(companyId: number, payload: {}) {
        return this.httpClient.post(`/company/${companyId}/documentTemplate/list`, payload).pipe(
            map((response: ImagilityBaseResponse) => {
                if (response && (response.status === 200 || response.status === 204)) {
                    return response;
                }
            })
        );
    }

    getI140UPdateDecisionDetails(superTaskId: number, i140CaseId: number) {
        return this.httpClient.get(`/task/${superTaskId}/case/${i140CaseId}/decision`).pipe(
            map((response: ImagilityBaseResponse) => {
                if (response && (response.status === 200 || response.status === 204)) {
                    return response;
                }
            })
        );
    }

    getLastUsedTemplate(superTaskId: number, caseId: number) {
        return this.httpClient.get(`/task/${superTaskId}/case/${caseId}/templateInfo`).pipe(
            map((response: ImagilityBaseResponse) => {
                if (response.status === 200 || response.status === 204) {
                    return response;
                }
            })
        );
    }

    deleteTemplateInfo(superTaskId: number, caseId: number, infoType: string) {
        return this.httpClient.delete(`/task/${superTaskId}/case/${caseId}/infoType/${infoType}`).pipe(
            map((response: ImagilityBaseResponse) => {
                if (response.status === 200) {
                    return response;
                }
            })
        );
    }

    postI140UpdateDecisionDetails(superTaskId: number, i140CaseId: number, payload) {
        return this.httpClient.post(`api/v1/task/${superTaskId}/case/${i140CaseId}/review`, payload).pipe(
            map((response: ImagilityBaseResponse) => {
                if (response.status === 200) {
                    return response;
                }
            })
        );
    }

    postAddtionalInfo(superTaskId: number, caseId: number, payload: {}) {
        return this.httpClient.post(`/task/${superTaskId}/case/${caseId}/additionalInfo`, payload).pipe(
            map((response: ImagilityBaseResponse) => {
                if (response.status === 200) {
                    return response;
                }
            })
        );
    }


    deleteInfoType(superTaskId: number, caseId: number, infoType: string) {
        return this.httpClient.delete(`/task/${superTaskId}/case/${caseId}/infoType/${infoType}`).pipe(
            map((response: ImagilityBaseResponse) => {
                if (response && response.status === 200) {
                    return response;
                }
            })
        )
    };

    postInfoType(superTaskId: number, caseId: number, infoType: string, payload: {}) {
        return this.httpClient.post(`/task/${superTaskId}/case/${caseId}/infoType/${infoType}`, payload).pipe(
            map((response: ImagilityBaseResponse) => {
                if (response.status === 200) {
                    return response;
                }
            })
        );
    }


    getPreview(superTaskId: number, caseId: number, letterType: string, payload) {
        return this.httpClient.post(`/task/${superTaskId}/case/${caseId}/letter/${letterType}/preview`, payload).pipe(
            map((response: ImagilityBaseResponse) => {
                if (response.status === 200 || response.status === 204) {
                    return response;
                }
            })
        );
    }

    updatePreview(superTaskId: number, caseId: number, letterType: string, payload) {
        return this.httpClient.post(`/task/${superTaskId}/case/${caseId}/letter/${letterType}/preview/save`, payload).pipe(
            map((response: ImagilityBaseResponse) => {
                if (response.status === 200) {
                    return response;
                }
            })
        );
    }

    generatePDF(superTaskId: number, caseId: number, letterType: string, payload) {
        return this.httpClient.post(`/task/${superTaskId}/case/${caseId}/letter/${letterType}/generate/pdf`, payload).pipe(
            map((response: ImagilityBaseResponse) => {
                if (response.status === 200) {
                    return response;
                }
            })
        );
    }

    getTemplateInfo(superTaskId: number, caseId: number) {
        return this.httpClient.get(`/task/${superTaskId}/case/${caseId}/templateInfo`).pipe(
            map((response: ImagilityBaseResponse) => {
                if (response.status === 200) {
                    return response;
                }
            })
        );
    }

    getAdditionalInfo(superTaskId: number, caseId: number) {
        return this.httpClient.get(`/task/${superTaskId}/case/${caseId}/additionalInfo`).pipe(
            map((response: ImagilityBaseResponse) => {
                if (response.status === 200) {
                    return response;
                }
            })
        );
    }

    getLetterType(superTaskId: number, caseId: number, letterType: string) {
        return this.httpClient.get(`/task/${superTaskId}/case/${caseId}/letter/${letterType}`).pipe(
            map((response: ImagilityBaseResponse) => {
                if (response.status === 200 || response.status === 204) {
                    return response;
                }
            })
        )
    }

    postLetterType(superTaskId: number, caseId: number, letterType: string, payload: {}) {
        return this.httpClient.post(`/task/${superTaskId}/case/${caseId}/letter/${letterType}`, payload).pipe(
            map((response: ImagilityBaseResponse) => {
                if (response.status === 200) {
                    return response;
                }
            })
        )
    }

    getI140Documents(superTaskId: number, subTaskId: number, i140CaseId: number, docCategorCode: string) {
        return this.httpClient.get(`/superTask/${superTaskId}/subTask/${subTaskId}/gc/i140Case/${i140CaseId}/document/category/${docCategorCode}`).pipe(
            map((response: ImagilityBaseResponse) => {
                if (response.status === 200 || response.status === 204) {
                    return response;
                }
            })
        )
    }


    postI140UPdateDecisionDetails(superTaskId: number, i140CaseId: number, payload: {}) {
        return this.httpClient.post(`/task/${superTaskId}/case/${i140CaseId}/decision`, payload).pipe(
            map((response: ImagilityBaseResponse) => {
                if (response && (response.status === 200)) {
                    return response;
                }
            })
        )
    }

    getReviewPetitiondocDraft(taskId: number, caseId: number, draft: boolean) {
        //const encriptedDraftData = this.toEncrypt(draft)
        return this.httpClient.get(`/task/${taskId}/case/${caseId}/generate/${draft}/i140`).pipe(
            map((response: ImagilityBaseResponse) => {
                if (response.status === 200 || response.status === 204) {
                    return response;
                }
            })
        );
    }

    postReviewPetitiondocDraft(taskId: number, caseId: number, draft: boolean) {
        //const encriptedDraftData = this.toEncrypt(draft)
        return this.httpClient.post(`/task/${taskId}/case/${caseId}/generate/${draft}/i140`, {}).pipe(
            map((response: ImagilityBaseResponse) => {
                if (response.status === 200) {
                    return response;
                }
            })
        );
    }

    postNotifyReviewer(taskId: number, caseId: number) {
        return this.httpClient.post(`/task/${taskId}/case/${caseId}/notifyReviewer`, {}).pipe(
            map((response: ImagilityBaseResponse) => {
                if (response.status === 200) {
                    return response;
                }
            })
        );
    }

    syncDocument(superTaskId, subTaskId, i140CaseId, categoryCode) {
        return this.httpClient.post(`/superTask/${superTaskId}/subTask/${subTaskId}/gc/i140Case/${i140CaseId}/profileDoc/category/${categoryCode}/sync`, {}).pipe(
            map((response: ImagilityBaseResponse) => {
                if (response && response.status === 200) {
                    return response;
                }
            })
        );
    }

    postActionCode(superTaskId, actionCode, payload) {
        return this.httpClient.post(`/greenCard/task/${superTaskId}/actionCode/${actionCode}`, payload).pipe(
            map((response: ImagilityBaseResponse) => {
                if (response && response.status === 200) {
                    return response;
                }
            })
        );
    }

    getReceiptDetails(superTaskId, subTaskId, i140CaseId) {
        return this.httpClient.get(`/superTask/${superTaskId}/subTask/${subTaskId}/gc/i140Case/${i140CaseId}/receiptInfo`).pipe(
            map((response: ImagilityBaseResponse) => {
                if (response.status === 200 || response.status === 204) {
                    return response;
                }
            })
        );
    }

    postDocumentGetReceipt(taskStepId, receiptType, receiptNumber, receiptCategory) {
        return this.httpClient.post(`/document/taskStep/${taskStepId}/category/${receiptType}/entity/${receiptNumber}/fileCategory/${receiptCategory}`, {}).pipe(
            map((response: ImagilityBaseResponse) => {
                if (response && response.status === 200) {
                    return response;
                }
            })
        );
    }

    getDocumentReceipt(taskStepId, receiptType) {
        return this.httpClient.get(`/document/taskStep/${taskStepId}/category/${receiptType}`).pipe(
            map((response: ImagilityBaseResponse) => {
                if (response.status === 200 || response.status === 204) {
                    return response;
                }
            })
        );
    }

    deletDocumentGetReceipt(taskStepId, receiptType, receiptNumber, receiptCategory) {
        return this.httpClient.delete(`/document/taskStep/${taskStepId}/category/${receiptType}/entity/${receiptNumber}/fileCategory/${receiptCategory}`).pipe(
            map((response: ImagilityBaseResponse) => {
                if (response && response.status === 200) {
                    return response;
                }
            })
        );
    }

    postGetReceipt(subTaskId: number, actionCode: string, payload) {
        return this.httpClient.post(`/greenCard/task/${subTaskId}/actionCode/${actionCode}`, payload).pipe(
            map((response: ImagilityBaseResponse) => {
                if (response.status == 200) {
                    return response;
                }
            })
        )
    }

    getPetitionerDetails(companyId) {
        return this.httpClient.get(`/company/${companyId}`).pipe(
            map((response: ImagilityBaseResponse) => {
                if (response && (response.status === 200 || response.status === 204))
                    return response;
            })
        );
    }

    getImmigraionDoc(superTaskId, subTaskId, i140CaseId, fileCategory) {
        return this.httpClient.get(`/superTask/${superTaskId}/subTask/${subTaskId}/gc/i140Case/${i140CaseId}/profileDoc/category/${fileCategory}`).pipe(
            map((response: ImagilityBaseResponse) => {
                if (response && (response.status === 200 || response.status === 204)) {
                    return response;
                }
            })
        );
    }

    postImmigraionDoc(superTaskId, subTaskId, i140CaseId, fileCategory, payload) {
        return this.httpClient.post(`/superTask/${superTaskId}/subTask/${subTaskId}/gc/i140Case/${i140CaseId}/document/category/BENIMMDOC/fileCategory/${fileCategory}`, payload).pipe(
            map((response: ImagilityBaseResponse) => {
                if (response.status == 200) {
                    return response;
                }
            })
        )
    }

    deleteImmigraionDoc(superTaskId, subTaskId, i140CaseId, fileCategory) {
        return this.httpClient.delete(`/superTask/${superTaskId}/subTask/${subTaskId}/gc/i140Case/${i140CaseId}/document/${fileCategory}`).pipe(
            map((response: ImagilityBaseResponse) => {
                if (response && response.status === 200) {
                    return response;
                }
            })
        );
    }
    postCaseStatus(recieptNumber: string, taskId: number) {
        return this.httpClient.post(`/casestatus/createbytaskid?recieptNumber=${recieptNumber}&taskId=${taskId}`, {}).pipe(
            map((response: ImagilityBaseResponse) => {
                if (response.status == 200) {
                    return response;
                }
            })
        )
    }

    toEncrypt(_data) {
        var pharase = 'c63a423527b24f65'
        var data = _data;
        var key = CryptoJS.enc.Latin1.parse(pharase);
        var iv = CryptoJS.enc.Latin1.parse(pharase);
        var encrypted = CryptoJS.AES.encrypt(
            data,
            key,
            {
                iv: iv, mode: CryptoJS.mode.CBC,
                padding: CryptoJS.pad.ZeroPadding
            }).toString();

        var e64 = CryptoJS.enc.Base64.parse(encrypted);
        var eHex = e64.toString(CryptoJS.enc.Hex);

        return this.encriptedData = eHex
    }
}