import { E } from '@angular/cdk/keycodes';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { EncryptService } from 'src/app/modules/core/http/http-encrypt.service';
import { PetitionerService } from 'src/app/services/petitioner.service';
@Component({
  selector: 'app-recent-activity',
  templateUrl: './recent-activity.component.html',
  styleUrls: ['./recent-activity.component.scss']
})
export class RecentActivityComponent implements OnInit {
  // Complete data
  individualData:string[] = [];
  companyData:string[] = [];
  // Limited data for display
  limitedIndividualData = [];
  limitedCompanyData = [];
  // Columns to display
  individualDisplayedColumns: string[] = ['time', 'individualName', 'company'];
  companyDisplayedColumns: string[] = ['time', 'company'];
  intervalId: any;
  beneficiaryId: string;
  selectedIndex: number = 0; // Default selected tab index
  isBenActivity: boolean = true;
  recentActivityData: any;
  constructor(public toastr: ToastrService,
    private petetionService:PetitionerService,
    private router: Router, 
    private route:ActivatedRoute,
    private encryptService: EncryptService) { }
  ngOnInit(): void {
    this.getRecentActivityDetails(this.isBenActivity);
    // Limit the data to the first 10 entries
    this.limitedIndividualData = this.individualData.slice(0, 10);
    this.limitedCompanyData = this.companyData.slice(0, 10);
    // Update data every 30 seconds
    this.intervalId = setInterval(() => {
      this.getRecentActivityDetails(this.isBenActivity);
    }, 30000);
  }
  // This method will run when the tab selection changes
  onTabChange(event: any) {
    const selectedTab = event.tab;
   if(selectedTab.textLabel == "Individual"){
    this.isBenActivity = true;
   }
   else if(selectedTab.textLabel == "Company"){
    this.isBenActivity = false;
   }
   else{
    this.isBenActivity = true;
   }
   this.getRecentActivityDetails(this.isBenActivity);
  }
  getRecentActivityDetails(activity: boolean) {
    this.petetionService.getRecentActivity(activity).subscribe((data: any) => {
      if (data) {
        if (this.isBenActivity) {
          // Sort and limit individual data
          this.individualData =  data.sort((a, b) => this.convertToDate(b.time).getTime() - this.convertToDate(a.time).getTime());
          this.limitedIndividualData = this.individualData.slice(0, 10);
        } else {
          // Sort and limit company data
          this.companyData = data.sort((a, b) => this.convertToDate(b.time).getTime() - this.convertToDate(a.time).getTime());
          this.limitedCompanyData = this.companyData.slice(0, 10);
        }
      }
    });
  }
  convertToDate(timeString: string): Date {
    // Replace "P.M." with "PM" and "A.M." with "AM" to make the format compatible with Date parsing
    const formattedTime = timeString.replace("P.M.", "PM").replace("A.M.", "AM");
    return new Date(formattedTime);
}
  // Handlers for clicks
  onIndividualClick(element: any): void {
    sessionStorage.setItem('employeeId', element.beneficiaryId);
    sessionStorage.setItem('listBeneficiaryId', element.beneficiaryId);
    const customSelectedCompanyId: any = element.companyId;
    sessionStorage.setItem('companyId', customSelectedCompanyId);
    window.open(element.url, '_blank');
  
  }
  onCompanyClick(element: any): void {
    sessionStorage.setItem('listBeneficiaryId', element.beneficiaryId);
    const customSelectedCompanyId: any = element.companyId;
    localStorage.setItem('cutomSelectedCompanyId', customSelectedCompanyId);
    window.open(element.url, '_blank');
  }
  ngOnDestroy(): void {
    // Clear the interval when the component is destroyed to avoid memory leaks
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  }
}