import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment-timezone';
import { ToastrService } from 'ngx-toastr';
import { ApiServicesService } from '../services/api-services.service';
import { CustomErrorToastComponent } from 'custom-error-toast';

@Component({
  selector: 'app-beneficiary-econsent-acknowledgement',
  templateUrl: './beneficiary-econsent-acknowledgement.component.html',
  styleUrls: ['./beneficiary-econsent-acknowledgement.component.scss']
})
export class BeneficiaryEconsentAcknowledgementComponent implements OnInit {

  companyLogoImage: string;
  companyName: string;

  isEConsentModalVisible: boolean = false;
  eConsentData: any = '';
  isAcceptEConsent: boolean = false;
  companyId: number = null;
  beneficiaryId: number = null;
  isRequestFromLogin: boolean = false; //if beneficiary user come from send invite login flow
  accessTokenCode: number = null; // if beneficiary user come from request data and document flow
  eConsentPayload = {      
    "accepted": true,
    "beneficiaryId": null,        
    "companyId": null,
    "econsentDesc": '',
    "econsentTitle": '',
    "econsentDate": '',
    "econsentTime": '',  
    "econsentAccessCode": null,  
    "id": null      
  }

  @Output() notifyEConsentChange: EventEmitter<any> = new EventEmitter()

  constructor(
    private apiService: ApiServicesService,
    private toastService: ToastrService,
    private activatedRoute: ActivatedRoute, 
    private router: Router  
  ) { 
    
    const currentUrl = this.router.url;  // Get the current URL
    const urlSegments = currentUrl.split('/');  // Split the URL by "/"
    
    if (urlSegments[urlSegments.length - 1] === 'beneficiary-dashboard') {      
      this.isRequestFromLogin = true;
      this.beneficiaryId = parseInt(sessionStorage.getItem('beneficiaryId'));
    } else {
      this.isRequestFromLogin = false;            
      this.beneficiaryId = parseInt(sessionStorage.getItem('listBeneficiaryId'));
      this.accessTokenCode = parseInt(localStorage.getItem('accessCode'));
    }

  }

  ngOnInit(): void {    
    this.checkEConsentAcknowledgement();        
  }
  

  checkEConsentAcknowledgement() {
    const apiCall = this.isRequestFromLogin
      ? this.apiService.getBeneficiaryEConsentLogin(this.beneficiaryId, this.isRequestFromLogin)
      : this.apiService.getBeneficiaryEConsentReqData(this.beneficiaryId, this.isRequestFromLogin, this.accessTokenCode);
  
    apiCall.subscribe({
      next: (result: any) => {
        
        this.companyId ??= result.data?.companyId;
  
        if (!this.companyId) {
          return false;
        }
  
        this.eConsentPayload = result.data;
  
        if (result.status === 204 || (result.status === 200 && !result.data.accepted)) {
          this.loadEConsentData();
        }
      },
      error: (err) => this.handleApiError(err),
    });
  }

    loadCompanyDetails(){            
      this.apiService.getBeneficiary(this.accessTokenCode).subscribe((res: any) => {            
        this.companyLogoImage = (res.data.attorney.companyLogo) ? 'data:image/jpeg;base64,' + res.data.attorney.companyLogo : null;
        this.companyName = res.data.attorney.companyName ? res.data.attorney.companyName : '';
      });
    }
  
    loadEConsentData(): void {
      this.apiService.getEconsentMessage(this.companyId).subscribe({
        next: (result: any) => {
          if (result) {
              this.prepareEConsentPayload(result);    
            if (result.enable) {
              this.showModal();
            }
          }
        },
        error: (err) => this.handleApiError(err),
      });
    }
  
    prepareEConsentPayload(data: any): void {
      this.eConsentPayload = {
        ...this.eConsentPayload,
        companyId: data.companyId,
        econsentTitle: data.econsentTitle,
        econsentDesc: data.econsentDesc,
        beneficiaryId: this.beneficiaryId
      };      
      this.eConsentData = data;      
    }
  
    showModal(): void {
      this.isEConsentModalVisible = true;
    }
    
    hideModal(): void {
      this.isEConsentModalVisible = false;
    }
  
    logEConsentAcknowledgement(accepted: boolean): void {
      this.eConsentPayload.accepted = accepted;     
      this.eConsentPayload.econsentAccessCode = this.accessTokenCode;
      const now = moment.tz(); // Get current moment in local timezone
      this.eConsentPayload.econsentDate = now.format('DD MMM YYYY');  
      this.eConsentPayload.econsentTime = now.tz(moment.tz.guess()).format('hh:mm:ss A z');
      
      this.apiService.saveEconsent(this.eConsentPayload).subscribe({
        next: (response) => {
          this.toastService.success(response.message, "Success"); 
          this.hideModal();                   
          if (!accepted) {
            if (!this.isRequestFromLogin) {
              this.isAcceptEConsent = true;
              this.loadCompanyDetails();
              return;
            }          
            this.checkEConsentAcknowledgement();            
          }                    
        },
        error: (err) => this.handleApiError(err),
      });
    }
  
    acceptEConsent(): void {
      this.logEConsentAcknowledgement(true);
    }
    
    denyEConsent(): void {
      this.logEConsentAcknowledgement(false); 
      if(this.isRequestFromLogin === false){     
        this.isAcceptEConsent = true;  
        this.sendEConsentUpdate();
      }
    }
  
    backToEConsent(){
      this.checkEConsentAcknowledgement();      
      this.notifyEConsentChange.emit(true);
      this.isAcceptEConsent = false;
    }

    sendEConsentUpdate() {
      this.notifyEConsentChange.emit(false);
    }
  
    private handleApiError(error: any): void {
      CustomErrorToastComponent.showErrorToast(this.toastService, error);
    }

}
